/* App.css */

.App {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

span {
  cursor: pointer;
  font-size: 24px;
  margin-right: 5px;
}

h2 {
  margin-top: 20px;
}

div {
  margin-bottom: 10px;
}

.button_loadmore {
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  font-size: 16px;
}
.button_seemore {
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  font-size: 16px;
}

button:hover {
  background-color: #2980b9;
}
